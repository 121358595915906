import {
  downloadByGet, get, getByBlob, post,
} from '@/libs/axios'

export const getPrivacyPolicyByCurrent = () => get('/enterprise/privacyPolicy/GetPrivacyPolicyByCurrent')
export const DownloadTemplateFile = id => downloadByGet('/enterprise/privacyPolicy/GetPrivacyPolicyDisplayFile', { id })
export const DownloadHistoryPrivacyPolicy = id => downloadByGet('/enterprise/privacyPolicy/DownloadHistoryPrivacyPolicy', { id })
export const getHistoryByPolicyId = params => post('/enterprise/privacyPolicy/GetHistoryByPolicyId', params)
export const policyDel = params => post('/enterprise/privacyPolicy/Delete', params)
export const privacyPolicyPage = params => post('/enterprise/privacyPolicy/Page', params)
export const ListTemplate = () => get('/enterprise/privacyPolicy/ListTemplate')
export const GetTemplateInfo = id => get(`/enterprise/privacyPolicy/GetTemplateInfo?templateCode=${id}`)
export const PreviewByContent = id => get(`/enterprise/privacyPolicy/PreviewByContent?id=${id}`)
export const PreviewByFile = id => getByBlob(`/enterprise/privacyPolicy/PreviewByFile?id=${id}`)
export const DownloadPrivacyFile = id => downloadByGet(`/enterprise/privacyPolicy/DownloadFile?id=${id}`)
export const uploadFile = params => post('/enterprise/privacyPolicy/UploadFile', params)
export const GetPrivacyPolicyDisplayFile = id => get(`/enterprise/privacyPolicy/GetPrivacyPolicyDisplayFile?id=${id}`)
export const PolicyQueryInfo = id => get(`/enterprise/privacyPolicy/QueryInfo?id=${id}`)
export const GetIssueNoList = () => get('/enterprise/privacyPolicy/GetIssueNoList')
export const GetAutoIssueNo = () => get('/enterprise/privacyPolicy/GetAutoIssueNo')

//
export const saveOrUpdateByFile = (type, formData) => {
  const url = type === 2 ? '/enterprise/privacyPolicy/UpdateByFile' : '/enterprise/privacyPolicy/CreateByFile'
  return post(url, formData)
}
export const saveOrUpdateByTemplate = (formData, copy) => {
  let url = ''
  if (copy) {
    url = '/enterprise/privacyPolicy/CreateByTemplate'
  } else {
    url = formData.id ? '/enterprise/privacyPolicy/UpdateByTemplate' : '/enterprise/privacyPolicy/CreateByTemplate'
  }
  return post(url, formData)
}

export const saveOrUpdate = formData => {
  const url = formData.id ? '/enterprise/privacyPolicy/UpdatePrivacyPolicy' : '/enterprise/privacyPolicy/CreatePrivacyPolicy'
  return post(url, formData)
}
