<template>
  <b-card v-loading="loading">
    <el-form
      ref="form"
      label-width="80px"
      :label-position="'right'"
      :model="form"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
            label="标题"
            class="mr-1"
            prop="title"
            :rules="[{ required: true, message: '标题不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.title"
              placeholder="请输入标题"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="地区"
            class="mr-1"
            prop="area"
            :rules="[{ required: true, message: '请选择地区', trigger: 'blur' }]"
          >
            <el-input
              v-model="form.area"
              maxlength="20"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            class="mr-1"
            label="文档编号"
          >
            <el-input
              v-model="form.issueNo"
              placeholder="请输入编号"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
        class="mr-1"
        label="简介"
      >
        <el-input
          v-model="form.remark"
          type="textarea"
          placeholder="请输入简介"
        />
      </el-form-item>
    </el-form>
    <div class="flex-start match-height editor-wrap">
      <div class="doc-left">
        <div class="add-doc">
          <el-button
            class="btn-block text-center"
            size="small"
            type="primary"
            @click="addSection"
          >
            添加新模块
          </el-button>
        </div>
        <VuePerfectScrollbar
          v-loading="tagLoading"
          :style="{height: pageH - 380 +'px'}"
          :settings="settings"
        >
          <draggable
            v-model="form.detailList"
            tag="ul"
            handle=".mover"
            :options="{forceFallback:true, fallbackClass:'draggingStyle'}"
            @end="onEnd"
          >
            <li
              v-for="(item,index) in form.detailList"
              :key="index"
              :class="activeIndex == index ? 'doc-active' : ''"
              class="section-item"
            >
              <div
                v-show="!item.editor"
                class="flex-start mover"
                style="width: 80%"
                @click="sectionClick(item,index)"
              >
                <feather-icon
                  icon="CircleIcon"
                  class="mr10"
                />
                <el-tooltip
                  v-if="item.directoryName.length >= 14"
                  class="item"
                  effect="light"
                  :content="item.directoryName"
                  placement="top"
                >
                  <span class="overflow-ellipsis directoryName">{{ item.directoryName }}</span>
                </el-tooltip>
                <span
                  v-else
                  class="overflow-ellipsis directoryName"
                >{{ item.directoryName }}</span>
              </div>
              <div
                v-show="!item.editor"
                class="flex-end section-operate"
              >
                <i
                  class="el-icon-edit mr10"
                  @click="editorShow(item)"
                />
                <i
                  class="el-icon-delete"
                  @click.stop="delSection(index)"
                />
              </div>
              <el-input
                v-show="item.editor"
                v-model="item.directoryName"
                v-input-filter
                maxlength="100"
                @blur="updateDirectoryName(item,index)"
              >
                <template slot="append">
                  <i
                    class="el-icon-check"
                    @click="updateDirectoryName(item,index)"
                  />
                </template>
              </el-input>

            </li>
          </draggable>
        </VuePerfectScrollbar>
      </div>
      <div class="doc-right">
        <div
          v-for="(item,index) in form.detailList"
          v-if="activeIndex == index"
          :key="index"
        >
          <!--          <Toolbar-->
          <!--            style="border-bottom: 1px solid #ccc"-->
          <!--            :editor="editor"-->
          <!--            :default-config="toolbarConfig"-->
          <!--            :mode="mode"-->
          <!--          />-->
          <!--          <Editor-->
          <!--            v-model="item.content"-->
          <!--            style="overflow-y: hidden;"-->
          <!--            :default-config="editorConfig"-->
          <!--            :mode="mode"-->
          <!--            @onCreated="onCreated"-->
          <!--          />-->
          <EditorVue :content.sync="item" />
        </div>
      </div>
    </div>
    <div class="text-center mt20">
      <el-button @click="back">
        取 消
      </el-button>
      <el-button
        type="primary"
        size="small"
        @click="submit"
      >
        保 存
      </el-button>
      <el-button
        size="small"
        type="primary"
        @click="preview"
      >
        预 览
      </el-button>
    </div>
  </b-card>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
// eslint-disable-next-line import/no-cycle
import {
  GetTemplateInfo, PolicyQueryInfo, saveOrUpdateByTemplate, GetAutoIssueNo,
} from '@/api/enterprise/prolicy'
// eslint-disable-next-line import/no-cycle
import { error, success } from '@core/utils/utils'
import EditorVue from '@core/components/editor-vue/EditorVue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VuePerfectScrollbar, Editor, Toolbar, draggable, EditorVue,
  },
  data() {
    return {
      countryProps: {
        emitPath: false,
      },
      form: {
        title: '',
        area: '中国',
        issueNo: '',
        remark: '',
        detailList: [],
        id: '',
      },
      tagLoading: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30,
      },
      activeIndex: 0,
      tId: this.$route.query.tId,
      pId: this.$route.query.pId,
      c: this.$route.query.c,
      htmlStr: '',
      editor: null,
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default',
      isInit: true,
      loading: false,
    }
  },
  created() {
    if (this.pId) {
      this.policyQueryInfo()
    } else {
      this.getTemplateInfo(this.tId)
      this.getAutoIssueNo()
    }
  },
  mounted() {
    this.navActiveInit(1, '隐私政策', '管理框架')
  },
  destroyed() {
    this.navActiveInit(2, '隐私政策', '管理框架', '/policy')
  },
  methods: {
    getAutoIssueNo() {
      GetAutoIssueNo().then(res => {
        const resData = res.data
        this.form.issueNo = resData.data
      })
    },
    preview() {
      let htmlStr = ''
      this.form.detailList.forEach(d => {
        if (d.content) {
          htmlStr += d.content
        }
      })
      if (htmlStr) {
        localStorage.setItem('htmlStr', htmlStr)
        const routeUrl = this.$router.resolve({
          path: '/policy-read',
        })
        window.open(routeUrl.href, '_blank')
      } else {
        error('内容为空，无法预览')
      }
    },
    onEnd(event) {
      this.activeIndex = event.newIndex
    },
    back() {
      this.$router.push({
        path: '/policy',
      })
    },
    addSection() {
      this.form.detailList.push({
        content: '',
        directoryName: '',
        editor: true,
      })
      this.activeIndex = this.form.detailList.length - 1
      this.htmlStr = this.form.detailList[this.activeIndex].content
    },
    delSection(index) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        this.form.detailList.splice(index, 1)
        this.activeIndex = 0
        this.htmlStr = this.form.detailList[0].content
      })
    },
    updateDirectoryName(item, index) {
      if (item.directoryName.length === 0) {
        this.form.detailList.splice(index, 1)
      } else {
        item.editor = false
      }
    },
    editorShow(item) {
      item.editor = true
    },
    getTemplateInfo(code) {
      this.tagLoading = true
      GetTemplateInfo(code).then(res => {
        this.tagLoading = false
        const resData = res.data
        if (resData.code === 0) {
          resData.data.forEach(r => {
            this.form.detailList.push({
              content: r.content,
              directoryName: r.directoryName,
              editor: false,
            })
          })
          this.htmlStr = this.form.detailList[0].content
        }
      })
    },
    policyQueryInfo() {
      this.tagLoading = true
      PolicyQueryInfo(this.pId).then(res => {
        this.tagLoading = false
        const resData = res.data
        if (resData.code === 0) {
          // this.form = resData.data
          this.form.detailList = []
          this.form.title = resData.data.title
          this.form.area = resData.data.area
          this.form.issueNo = resData.data.issueNo
          this.form.remark = resData.data.remark
          this.form.id = resData.data.id
          resData.data.detailList.forEach(r => {
            this.form.detailList.push({
              content: r.content,
              directoryName: r.directoryName,
              editor: false,
            })
          })
          if (this.form.detailList.length > 0) {
            this.htmlStr = this.form.detailList[0].content
          }
          if (this.c) {
            this.form.id = 0
            this.form.issueNo = ''
            this.form.title = `${this.form.title}-副本`
            this.getAutoIssueNo()
          }
        }
      }).catch(() => {
        this.tagLoading = false
      })
    },
    sectionClick(item, index) {
      this.htmlStr = item.content
      this.activeIndex = index
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    change() {
      if (!this.isInit) {
        this.form.detailList[this.activeIndex].content = this.htmlStr
      }
      this.isInit = false
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.detailList.length > 0) {
            this.loading = true
            if (this.c) {
              delete this.form.id
            }
            const newInfoModels = []
            this.form.detailList.forEach((m, index) => {
              if (m.directoryName.length > 0) {
                newInfoModels.push(m)
              }
            })
            this.form.detailList = newInfoModels
            saveOrUpdateByTemplate(this.form, this.c).then(res => {
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.back()
              } else {
                error(resData.msg)
              }
            }).catch(() => {
              this.loading = false
            })
          } else {
            error('模块不能为空')
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
.directoryName {
  cursor: move;
}
.doc-dropdown .btn-secondary,
.doc-dropdown .btn-secondary:hover {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.feather-more-vertical {
  color: #6e6b7b !important;
}
.editor-wrap {
  height: 100%;
  border: 1px solid #ebe9f1;
  align-items: baseline;
  border-radius: 4px;
  .doc-left {
    width: 260px;
    border-right: 1px solid #ebe9f1;
    height: 100%;
    .add-doc {
      padding: 10px;
    }
    .section-item {
      padding: 10px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon-w_wendang {
        margin-right: 10px;
      }
      i {
        font-size: 20px;
      }
      .el-input-group__append,
      [dir] .el-input-group__prepend {
        padding: 0 5px !important;
      }
    }
    .section-operate {
      display: none;
      position: absolute;
      right: 10px;
    }
    .section-item:hover {
      .section-operate {
        display: block;
      }
    }
    .section-item.doc-active {
      border-left: 2px solid #0d40b2;
      color: #0d40b2;
      position: relative;
    }
    .tag-title {
      padding: 10px;
      border-top: 1px solid #ebe9f1;
      border-bottom: 1px solid #ebe9f1;
      align-items: center;
      /*color: #b9b9c3;*/
    }
  }
  .doc-right {
    width: calc(100% - 260px);
    height: 100%;
    .doc-search-wrap {
      width: 100%;
      padding: 0 20px;
      border-bottom: 1px solid #ebe9f1;
      height: 40px;
      .doc-search-left {
        width: 80%;
        .el-input__inner {
          border: none !important;
        }
      }
      .el-dropdown {
        display: flex;
        align-items: center;
      }
    }

    .doc-li {
      /*transition: all .2s;*/
      cursor: pointer;
      padding: 20px 10px;
      border-bottom: 1px solid #ebe9f1;
      color: #6e6b7b;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .feather-more-vertical {
        margin-right: 10px;
        position: relative;
        top: 2px;
        opacity: 0;
      }
      .documentName {
        /*margin-left: 10px;*/
        font-size: 12px;
        color: #6e6b7b;
      }
    }
    .doc-li:hover {
      transform: translateY(-4px);
      box-shadow: 0 3px 10px 0 #ebe9f1;
      .feather-more-vertical {
        opacity: 1;
      }
    }
  }
}
</style>
<style>
.w-e-text-container table td,
.w-e-text-container table th {
  border: 1px solid var(--w-e-textarea-border-color);
  padding: 3px 5px;
}
</style>
